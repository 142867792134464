<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-5 justify-content-center">
                <!-- <SideNavOnlineAdmission></SideNavOnlineAdmission> -->
                <b-col cols="12" md="5" class="">
                    <b-card>
                        <b-card-body>
                            <b-card-text class="text-center">
                                <feather-icon size="4x" class="bg-danger text-white rounded-circle" icon="XIcon" />
                                <h3 class="mt-2">We regret to inform you that your payment has failed to process. Please
                                    review your payment details and try again or contact support for assistance.</h3>
                                <!-- <b-button variant="primary" class="mt-2">Go Home</b-button> -->
                                <router-link :to="{ name: 'admission', params: { institute } }" tag="button" class="btn btn-primary mt-2">
                                    Go Home
                                </router-link>
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BCard, BCardText, BButton, BContainer, BRow, BCol, BCardBody
} from 'bootstrap-vue'

import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue'
import SideNavOnlineAdmission from '@/views/components/nav/SideNavOnlineAdmission.vue';
import axios from 'axios';


export default {
    components: {
        BCard,
        BCardText,
        BButton,
        BContainer,
        BRow,
        BCol,
        NavOnlineAdmission,
        SideNavOnlineAdmission,
        BCardBody
    },
    data() {
        return {
            institute: this.$route.params.institute,
        }
    },
    computed: {

    },
    directives: {

    },
    props: {

    },

    created() {

    },

    methods: {


    }
}
</script>
<style scoped></style>
  