<template>
    <b-col cols="12" md="3" class="">
        <b-card>
            <div>
                <b-list-group class="nav">
                    <b-list-group-item button class="step" :class="{ active: isRouteActive('form') }" @click="removeData">
                        <router-link :to="{ name: 'form', params: { institute, formId } }">Create
                            New
                            Application</router-link>
                    </b-list-group-item>

                    <b-list-group-item button class="step" :class="{ active: isRouteActive('formDetails') }">
                        <router-link v-if="temporaryId !== null"
                            :to="{ name: 'formDetails', params: { institute, formId, temporaryId } }">
                            Add Details Information
                        </router-link>
                        <span v-else>Add Details Information</span>
                    </b-list-group-item>

                    <b-list-group-item button class="step" :class="{ active: isRouteActive('attachment') }">
                        <router-link v-if="temporaryId !== null"
                            :to="{ name: 'attachment', params: { institute, formId, temporaryId } }">
                            Attachment Document
                        </router-link>
                        <span v-else>Attachment Document</span>
                    </b-list-group-item>

                    <b-list-group-item button class="step" :class="{ active: isRouteActive('onlinePayment') }">
                        <router-link v-if="temporaryId !== null" :to="{ name: 'onlinePayment', params: { institute } }">
                            Payment
                        </router-link>
                        <span v-else>Payment</span>
                    </b-list-group-item>
                    
                </b-list-group>
            </div>
        </b-card>
    </b-col>
</template>
    
<script>
import { BContainer, BCol, BListGroup, BListGroupItem, BCard } from 'bootstrap-vue';
import { numeric } from 'vee-validate/dist/rules';
import axios from 'axios';

export default {
    components: {
        BContainer,
        BCol,
        BListGroup,
        BListGroupItem,
        BCard
    },

    data() {
        return {
            institute: localStorage.getItem('institute') || null,
            formId: localStorage.getItem('formId') || null,
            temporaryId: localStorage.getItem('temporaryId') || null,
        };
    },
    created() {
        this.getapplicant();

    },
    // props: ['institute', 'formId', 'temporaryId'],
    methods: {
        getapplicant() {
            axios.get(process.env.VUE_APP_API_URL + `get-applicant/?tempId=` + this.temporaryId)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        removeData() {
            console.log('Data is being removed');
            // localStorage.removeItem('institute');
            // localStorage.removeItem('formId');
            localStorage.removeItem('applicantId');
            localStorage.removeItem('temporaryId');
            this.$router.push({ path: `/admission/${this.institute}/${this.formId}`, });
            setTimeout(() => {
                location.reload();
            }, 100);
        },
        isRouteActive(routeName) {
            // Check if the current route name matches the specified routeName
            return this.$route.name === routeName;
        },
        // getData() {
        //     const institute = this.$route.params.institute;
        //     const formId = this.$route.params.formId;
        //     const temporaryId = this.$route.params.temporaryId;
        //     console.log('Data is being fetched'); // Replace with your data fetching logic
        // }
    }
}
</script>
<style scoped>
.step {
    border: none;
    text-align: left;
}

a {
    color: black;
}

.active a {
    color: white !important;
}

.step a{
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
}
</style>
  